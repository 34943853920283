import {effect, inject, Injectable, signal, Signal, WritableSignal} from '@angular/core';
import {GatewayService} from "@shared/services/gateway.service";
import {endpoints} from "../../../endpoints/endpoints";
import {IReturnTruck, IReturnTruckDto} from "@shared/interfaces/return-truck.interface";
import {ReturnTruckModel} from "@shared/models/return-truck.model";
import {ActivatedRoute, ActivationEnd, Router} from "@angular/router";
import {filter, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "@env/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Block} from "@angular/compiler";
import {EImageDefinitionType} from "@shared/enums/imageDefinitionType.enum";
import {IReturnTruckImage} from "@shared/interfaces/return-truck-image.interface";

@Injectable({
	providedIn: 'root',
})
export class TrucksService {
	private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
	private gatewayService: GatewayService = inject(GatewayService);
	private http: HttpClient = inject(HttpClient);
	private router = inject(Router);

	private storage = {
		allTrucks: <WritableSignal<ReturnTruckModel[]>>signal([]),
		selectedTruckId: <WritableSignal<string | null>>signal(null),
		selectedTruck: <WritableSignal<ReturnTruckModel | null>>signal(null)
	}

	constructor() {
		/*this.activatedRoute.firstChild?.params.subscribe(params => {
			if(params.hasOwnProperty('truckId')){
				this.selectedTruckId = params['truckId'];
			}
		})*/

		this.router.events
			.pipe(
				filter(e => (e instanceof ActivationEnd) && (Object.keys(e.snapshot.params).length > 0)),
				map(e => e instanceof ActivationEnd ? e.snapshot.params : {})
			)
			.subscribe(params => {
				if(params.hasOwnProperty('truckId')){
					this.selectedTruckId = params['truckId'];
				} else {
					this.selectedTruckId = null;
				}
			});

	}

	set selectedTruckId(truckId: string | null) {
		this.storage.selectedTruckId.set(truckId);
		if(this.storage.selectedTruckId() != null){
			this.loadTruckById(this.storage.selectedTruckId() as string).then(res=>{
				this.storage.selectedTruck.set(new ReturnTruckModel(res));
			})
		} else {
			this.storage.selectedTruck.set(null);
		}
	}
	get allTrucks(): WritableSignal<ReturnTruckModel[]> {
		return this.storage.allTrucks;
	}

	get selectedTruck(): WritableSignal<ReturnTruckModel | null> {
		return this.storage.selectedTruck;
	}

	set selectedTruck(truck:ReturnTruckModel | null) {
		this.storage.selectedTruck.set(truck);
	}

	public updateSelectedTruck() {
		if(this.storage.selectedTruckId()){
			if(this.storage.allTrucks()){
				this.storage.allTrucks().forEach(truck=>{
					if(truck.id == parseInt(this.storage.selectedTruckId() as string)){
						this.storage.selectedTruck.set(truck);
					}
				})
			}
		}
	}

	public getReturnTrucks(): Promise<IReturnTruckDto[]> {
		return new Promise((resolve, reject) => {
			this.gatewayService.get({
				url: endpoints.overview.all
			}).then((res: IReturnTruckDto[])=>{
				if(res){
					this.storage.allTrucks.set(res.map(el=>new ReturnTruckModel(el)));
					this.updateSelectedTruck();
				}
				resolve(res)
			},err=>{
				reject();
			})
		})
	}

	public loadTruckById(truckId: string): Promise<IReturnTruckDto> {
		return new Promise((resolve, reject) => {
			this.gatewayService.get({
				url: endpoints.overview.byId.replace('{truckId}',truckId)
			}).then((res: IReturnTruckDto)=>{
				resolve(res)
			},err=>{
				reject();
			})
		})
	}

	public updateTruckById(truck: Partial<ReturnTruckModel>): Promise<IReturnTruckDto> {
		return new Promise((resolve, reject) => {
			this.gatewayService.patch({
				url: endpoints.overview.byId.replace('{truckId}',String(this.storage.selectedTruckId())),
				data: truck
			}).then((res: IReturnTruckDto)=>{
				resolve(res);
			},err=>{
				reject();
			})
		})
	}

	public deleteImageById(imageId: number): Promise<any> {
		return new Promise((resolve, reject) => {
			this.gatewayService.delete({
				url: endpoints.images.byId.replace('{imageId}',String(imageId)),
			}).then(res=>{
				resolve(res);
			},err=>{
				reject();
			})
		})
	}

	public uploadImage(truckId: number, file: File, type: EImageDefinitionType): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = environment.apiEndpoint + '/' + endpoints.images.upload.replace('{returnTruckId}',String(truckId));
			let formData: FormData = new FormData();
			formData.append('file', file, file.name);
			formData.append('definitionType', type);
			let headers = new HttpHeaders();
			headers.append('Content-Type', 'multipart/form-data');
			this.http.post(url, formData, {
				headers: headers,
			}).subscribe(next=>{
				return resolve(next);
			}, error => {
				reject();
			});
		})
	}


}
